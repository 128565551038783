// Chakra imports
import { Box, Button, Grid, useDisclosure } from "@chakra-ui/react";
// Custom components
import Banner from "../../../views/admin/profile/components/Banner";
import General from "../../../views/admin/profile/components/General";
import Notifications from "../../../views/admin/profile/components/Notifications";
import Projects from "../../../views/admin/profile/components/Projects";
// import Storage from "../../../views/admin/profile/components/Storage";
// import Upload from "../../../views/admin/profile/components/Upload";
// Assets
import banner from "../../../assets/img/auth/banner.png";
import avatar from "../../../assets/img/avatars/avatar4.png";
import React from "react";
import EditProfileModal from "./components/EditProfileModal";
import { useDispatch, useSelector } from "react-redux";
import { get_UserInfo } from "../../../api/services/userService";
import { PROFILEINFO } from "../../../Redux/Actions";

export default function Overview() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const ProfileInfo = useSelector((data) => data?.userReducer?.userData);

  const getUserData = async () => {
    try {
      const response = await get_UserInfo();
      const data = response?.data?.data
      dispatch(PROFILEINFO(data));
    } catch (err) {
      if(err?.status == 401){
        localStorage.removeItem('LoginToken');
        window.location.reload();
      }
    }
  };

  return (
    <>
      <EditProfileModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} avatar={avatar} getUserData={getUserData}/>

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {/* Main Fields */}

        <div className="d-flex justify-content-end mt-2">
          <Button onClick={onOpen} colorScheme="blue" size="md">
            Edit Profile
          </Button>
        </div>
      <Grid
        mb='20px'
        templateColumns={{ base: "1fr", lg: "repeat(1, 1fr)" }} 
        templateRows={{
          base: "1fr",
          lg: "repeat(1, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <div className="mt-3">
          <Banner
            gridArea="1 / 1 / 2 / 2"
            banner={banner}
            avatar={ProfileInfo?.profile}
            name={`${ProfileInfo?.f_name} ${ProfileInfo?.l_name}`}
            job="Product Designer"
            posts="17"
            followers="9.7k"
            following="274"
          />
        </div>
      </Grid>


      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
       
        <General
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 1 / 1 / 1" }}
          minH='365px'
          pe='20px'
          ProfileInfo={ProfileInfo}
        />

        {/* <Projects
          gridArea='1 / 2 / 2 / 2'
          banner={banner}
          avatar={avatar}
          name='Adela Parkson'
          job='Product Designer'
          posts='17'
          followers='9.7k'
          following='274'
        /> */}

        {/* <Notifications
          used={25.6}
          total={50}
          gridArea={{
            base: "3 / 1 / 4 / 2",
            lg: "2 / 1 / 3 / 3",
            "2xl": "1 / 3 / 2 / 4",
          }}
        /> */}
      </Grid>
      </Box>
    </>
  );
}
