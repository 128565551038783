import { Route, Routes, useLocation, Navigate } from "react-router";
import React, { useState } from 'react'
import Home from "./Pages/Home"
import CheckSelectedId from './Components/CheckSelectedId';
import FillDetails from "./Pages/FillDetails"
import About from "./Pages/About"
import MyResume from "./Pages/MyResumes"
import Login from './Pages/Login';
import Register from './Pages/Register';
import ForgotPassword from './Pages/ForgotPassword';
import Footer from './Components/Footer/Footer';
import NewNavBar from "./Components/NewNavbar/NavBar";
import PricingPlan from "./Pages/PricingPlan/PricingPlan"
// import Dashboard from "./Pages/Dashboard/Dashboard";
import AdminLayout from './layouts/admin';
// import RTLLayout from './layouts/rtl';
import initialTheme from './theme/theme';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import TemplateCard from "./Pages/TemplateCard";
import MembershipTransaction from "./views/admin/MembershipTransaction";
import ResumeView from "./Pages/ResumeView";
import PrivateRoute from "./PrivateRoute";
import CvTemplateCard from "./Pages/CvTemplateCard";
import MyCv from "./Pages/MyCv";
import Blog from "./Pages/blog/Blog";
import BlogDetails from "./Pages/blog/BlogDetail";
import AllBlog from "./Pages/blog/AllBlog";
import CategoryBlog from "./Pages/blog/CategoryBlog";


const RouterNavigate = () => {
  const location = useLocation();
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  const showNavbar =
    // location?.pathname !== '/login' &&
    //   location?.pathname !== '/register' &&
    // location?.pathname !== "/forgotpassword" &&
    !/^\/publiclink\/[^/]+$/.test(location?.pathname) &&
    location?.pathname !== "/member/default" &&
    location?.pathname !== "/member/profile" &&
    location?.pathname !== "/member/myresume" &&
    location?.pathname !== "/member/templates" &&
    location?.pathname !== "/member/pricingplan" &&
    location?.pathname !== "/member/transactions" &&
    location?.pathname !== "/member/pricingplan/membershiptransaction" &&
    location?.pathname !== "/member/membershiptransaction"
  //  && !/^\/[^/]+$/.test(location?.pathname);


  const showFooter =
    location?.pathname !== '/login' &&
    location?.pathname !== '/register' &&
    location?.pathname !== "/forgotpassword" &&
    !/^\/publiclink\/[^/]+$/.test(location?.pathname) &&
    location?.pathname !== "/member/default" &&
    location?.pathname !== "/member/profile" &&
    location?.pathname !== "/member/myresume" &&
    location?.pathname !== "/member/templates" &&
    location?.pathname !== "/member/pricingplan" &&
    location?.pathname !== "/member/transactions" &&
    location?.pathname !== "/member/pricingplan/membershiptransaction" &&
    location?.pathname !== "/member/membershiptransaction"


  return (<>
    {showNavbar && <NewNavBar />}
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/template/FillDetails" element={<CheckSelectedId> <FillDetails /> </CheckSelectedId>} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/MyResume" element={<PrivateRoute Component={MyResume} />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/forgotpassword" element={<ForgotPassword />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/cvtemplatecard/FillDetails" element={<CheckSelectedId> <FillDetails /> </CheckSelectedId>} />
      <Route exact path="/templatecard" element={<TemplateCard />} />
      <Route exact path="/publiclink/:uuid" element={<ResumeView />} />
      <Route exact path="/cvtemplatecard" element={<CvTemplateCard />} />
      <Route exact path="/pricingplan" element={<PricingPlan />} />
      <Route exact path="/blog" element={<Blog />} />
      <Route exact path="/blog-details/:id" element={<BlogDetails />} />
      <Route exact path="/category-blog/:id" element={<CategoryBlog />} />
      <Route exact path="/all-blogs" element={<AllBlog />} />


      <Route exact path="/mycv" element={<PrivateRoute Component={MyCv} />} />

    </Routes>

    <ChakraProvider theme={currentTheme}>

      <Routes>
        <Route path="member/*" element={<AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />}>
          <Route path="membershiptransaction" element={<MembershipTransaction />} />
        </Route>
        <Route path="/" element={<Navigate to="/" replace />} />
      </Routes>

    </ChakraProvider>

    {/* <Route path="rtl/*" element={<RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />}/> */}

    {showFooter && <Footer />}
  </>)
};

export default RouterNavigate;