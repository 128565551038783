import { templates } from "../Data/templates";
import { SELECTTEMPLATE } from "../Redux/Actions";
import DarkEffect from "../Components/DarkEffect";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Stack } from "@mui/material";
import { MdOutlineFileCopy, MdCreate } from "react-icons/md";
import { WiStars } from "react-icons/wi";
import { FaRegHandPeace } from "react-icons/fa";
import { SlHandbag } from "react-icons/sl";
import { GoArrowDownLeft } from "react-icons/go";
import { useEffect, useState } from "react";
import { isTokenValid } from "../AuthCheck";
import { toast } from "react-toastify";



const mapStateToProps = (state) => ({
  selectedTemplateId: state.selectedTemplateReducer.selectedTemplateId,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedTemplateId: (id) => dispatch(SELECTTEMPLATE(id)),
});

const TemplateCard = (props) => {
  const navigate = useNavigate();
  const AuthCheck = isTokenValid();
  const location = useLocation();

  const [tabValue, setTabValue] = useState(location?.state?.value || "all-templates")

  // useEffect(() => {
  //   // Clear state after component mounts
  //   navigate(location.pathname, { replace: true, state: {} });
  // }, [navigate, location.pathname]);

  useEffect(() => {
    if (location.state?.value) {
      setTabValue(location.state.value);
    }
  }, [location.state]);


  const navigateToFill = (id) => {
    props.setSelectedTemplateId(id);
    navigate("/template/FillDetails");
  };

  const LoginFirst = () => {
    toast.warn("Please log in first, then create your resume.");
    navigate("/login");
  }

  return (
    <>
      <div className="template-card-container-1">
        <h1 className="template-card-title">Resume templates</h1>
        <p className="template-card-description">
          Each resume template is designed to follow the exact rules you need to
          get hired faster.
          <br />
          Use our resume templates and get free access to 18 more career tools!
        </p>
        {/* <button className="template-card-btn">Create my resume</button> */}
      </div>

      <div className="template-card-container-2">
        <nav className="template-card-menu">
          <button className={tabValue == "all-templates" ? "acitve-template-card-menu-item" : "template-card-menu-item"} onClick={() => setTabValue("all-templates")}>
            <span className="template-card-icon">
              <MdOutlineFileCopy size={26} />
            </span>
            All templates
          </button>
          <button className={tabValue == "simple" ? "acitve-template-card-menu-item" : "template-card-menu-item"} onClick={() => setTabValue("simple")}>
            <span className="template-card-icon">
              <WiStars size={40} />
            </span>
            Simple
          </button>
          <button className={tabValue == "modern" ? "acitve-template-card-menu-item" : "template-card-menu-item"} onClick={() => setTabValue("modern")}>
            <span className="template-card-icon">
              <FaRegHandPeace size={24} />
            </span>
            Modern
          </button>
          <button className={tabValue == "creative" ? "acitve-template-card-menu-item" : "template-card-menu-item"} onClick={() => setTabValue("creative")}>
            <span className="template-card-icon">
              <MdCreate />
            </span>
            Creative
          </button>
          <button className={tabValue == "professional" ? "acitve-template-card-menu-item" : "template-card-menu-item"} onClick={() => setTabValue("professional")}>
            <span className="template-card-icon">
              <SlHandbag />
            </span>
            Professional
          </button>
        </nav>
        <hr className="template-card-divider" />
      </div >

      <div className="Home">
        <div className="home-templates-cont">
          <Stack
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: {
                sm: "1fr 1fr ",
                md: "1fr 1fr ",
                lg: "1fr 1fr 1fr",
                xl: "1fr 1fr 1fr",
              },
              gridGap: "30px",
            }}
          >

            {(tabValue == 'all-templates' || tabValue == 'simple') &&

              templates.map((template) => {
                return (
                  <div
                    key={template.id}
                    id="template"
                    className="templates-img-cont"
                  >
                    <img
                      className="template-img"
                      src={template.template_img}
                      alt={template.template_name}
                    />
                    <DarkEffect />

                    {AuthCheck ?
                      <Button
                        className="use-template-btn"
                        onClick={() => navigateToFill(template.id)}
                        size="medium"
                        variant="contained"
                      >
                        Use Template
                      </Button>
                      :
                      <Button
                        className="use-template-btn"
                        size="medium"
                        onClick={() => LoginFirst()}
                        variant="contained"
                      >
                        Use Template
                      </Button>}
                  </div>
                );
              })
            }
          </Stack>

          {(tabValue == 'all-templates' || tabValue == 'simple') ?
            "" :
            <div className="d-flex justify-content-center">
              <h4>No Resume Available</h4>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateCard);
