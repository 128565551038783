import { Avatar, Button, Divider, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import "../assets/Styles/PersonalInfoComp.css";
import BackNextBtnComp from "./BackNextBtnComp";
import { connect } from "react-redux";
import Avatar1 from "react-avatar-edit";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ADDPERSONALINFO } from "../Redux/Actions";
import InputComp from "./InputComp";
import PersonalInfoAddressSection from "./PersonalInfoAddressSection";
import PhoneInput from "react-phone-input-2";

const mapStateToProps = (state) => ({
  personalInfo: state.personalInfoReducer.personalInfo,
});

const mapDispatchToProps = (dispatch) => ({
  onADDPERSONALINFO: (details) => dispatch(ADDPERSONALINFO(details)),
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme?.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme?.spacing(1),
  },
}));

const PersonalInfoComp = (props, setTab, tab) => {
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState("");
  // const [vertical, setVertical] = useState("top");
  // const [horizontal, setHorizontal] = useState("center");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm();

  // const [img, setImg] = useState(
  //   props.personalInfo.profileImg.length ? props.personalInfo.profileImg : ""
  // );

  const { personalInfo, NoPic } = props;

  // Manage state for the image source
  const [img, setImg] = useState(
    personalInfo?.profile?.length
      ? `https://resumecv.idea2reality.tech/storage/Profile/${personalInfo.profile}`
      : !NoPic
        ? "https://harvesthosts-marketing-assets.s3.amazonaws.com/wp-content/uploads/2021/11/whoknows-1.jpg"
        : ""
  );


  // const NoPic =
  //   props?.personalInfo?.firstName[0] +
  //   (props?.personalInfo?.lastName?.length > 0
  //     ? props?.personalInfo?.lastName[0]
  //     : " ");

  const [open, setOpen] = useState(false);
  const [storeImg, setStoreImg] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = (data) => {
    // if (!img) {
    //   setImageError("Please select and crop an image before uploading.");
    // } 
    // else {
    setLoading(true);
    props.onADDPERSONALINFO({ profileImg: img, ...data });

    setTimeout(() => {
      setLoading(false);
      props.setTab(props.tab + 1);
    }, 1000);
    // }
  };



  const onCrop = (view) => {
    setImg(view);
  };

  const onClose = (view) => {
    setImg(null);
  };

  // Windows Width
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const saveImage = () => {
    setStoreImg([{ img } ? img : " "]);
    setOpen(false);
  };

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const handlePhoneChange = (value, country) => {
    const phoneNum = value?.slice(2);
    const d_code = `${country?.format?.charAt(0)}${country?.dialCode}`;

    props.onADDPERSONALINFO({
      ...props.personalInfo,
      country_code: d_code,
      phone: phoneNum,
    })

    setValue("phone", phoneNum); // Set the phone number in the form state

    // Clear error if the number is valid (10 digits or more)
    if (phoneNum.length >= 10) {
      clearErrors("phone");
    } else {
      if (phoneNum.length > 0) {
        setValue("phone", phoneNum); // Keep updating the value
      }
    }
  };

  useEffect(() => {
    if (props?.personalInfo?.phone) {
      setValue(
        "phone",
        `${props?.personalInfo?.phone}`
      );
    }
  }, [props?.personalInfo, setValue]);


  useEffect(() => {
    setImg(
      personalInfo?.profile?.length
        ? `https://resumecv.idea2reality.tech/storage/Profile/${personalInfo.profile}`
        : !NoPic
          ? "https://harvesthosts-marketing-assets.s3.amazonaws.com/wp-content/uploads/2021/11/whoknows-1.jpg"
          : ""
    );
  }, [personalInfo.profile, NoPic]);


  return (
    <Paper className="personal-info-paper col-9" elevation={3}>
      {/* <Avatar
        sx={{ width: 120, height: 120, marginBottom: 1 }}
        alt="profile img"
        src={
          img?.length
            ? img
            : ""
        }
      /> */}

      <Avatar
        sx={{
          width: 120,
          height: 120,
          marginBottom: 1,
          backgroundColor: !img && NoPic ? "GrayText" : "transparent",
        }}
        alt="Profile"
        src={img}
      >
        {!img && NoPic && (
          <p style={{ color: "white", fontSize: "14px", textAlign: "center" }}>
            {NoPic}
          </p>
        )}
      </Avatar>

      <div>
        <Button
          className="change-profile-photo-btn"
          variant="outlined"
          onClick={handleClickOpen}
        >
          Select Profile Photo
        </Button>

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Upload Image
          </BootstrapDialogTitle>
          <DialogContent>
            <Avatar1
              width={windowSize.innerWidth > 900 && 300}
              height={windowSize.innerWidth > 500 ? 300 : 150}
              onCrop={onCrop}
              onClose={onClose}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus variant="contained" onClick={saveImage}>
              Upload
            </Button>
          </DialogActions>
        </BootstrapDialog>
        {imageError && (
          <p style={{ color: "#d32f2f", fontSize: 13, marginLeft: 14 }}>
            {imageError}
          </p>
        )}
      </div>

      <form onSubmit={handleSubmit(handleNext)}>
        <div className="personal-Info-form-fields">
          <InputComp
            title={"First Name"}
            asterisk={true}
            type={"text"}
            name={"firstName"}
            placeholder={"First Name"}
            maxLength={"15"}
            register={register}
            multiline={false}
            value={props.personalInfo.firstName}
            setValue={(value) =>
              props.onADDPERSONALINFO({
                ...props.personalInfo,
                firstName: value,
              })
            }
            error={errors.firstName ? true : false}
            errorMessage={errors.firstName ? errors.firstName.message : null}
          />

          <InputComp
            title={"Last Name"}
            name={"lastName"}
            asterisk={true}
            type={"text"}
            placeholder={"Last Name"}
            maxLength={"15"}
            register={register}
            multiline={false}
            value={props.personalInfo.lastName}
            setValue={(value) =>
              props.onADDPERSONALINFO({
                ...props.personalInfo,
                lastName: value,
              })
            }
            error={errors.lastName ? true : false}
            errorMessage={errors.lastName ? errors.lastName.message : null}
          />

          <InputComp
            title={"Email"}
            type={"email"}
            asterisk={true}
            name={"email"}
            placeholder={"Email"}
            register={register}
            multiline={false}
            value={props.personalInfo.email}
            setValue={(value) =>
              props.onADDPERSONALINFO({
                ...props.personalInfo,
                email: value,
              })
            }
            error={errors.email ? true : false}
            errorMessage={errors.email ? errors.email.message : null}
          />

          <div className="form-outline w-100">
            <label className="radio-btn-label input-title">
              Mobile
              <span className="" style={{ color: "red" }}>
                *
              </span>
            </label>

            <PhoneInput
              {...register("phone", {
                required: "Please fill this field.", // Required validation
                validate: {
                  validPhone: (value) =>
                    value.length >= 10 ||
                    "Phone number must be at least 10 digits.",
                },
              })}
              value={`${props?.personalInfo?.country_code}${props?.personalInfo?.phone}`}
              name="phone"
              onChange={handlePhoneChange}
              className="w-100"
              country={"in"}
              inputClass="resumePhoneInput"
              placeholder="phone"
            />
            {errors.phone && (
              <p style={{ color: "#d32f2f", fontSize: 13, marginLeft: 14 }}>
                {errors.phone.message}
              </p>
            )}
          </div>

        </div>
        {/* address */}
        <PersonalInfoAddressSection
          prop={props}
          register={register}
          errors={errors}
        />

        <div className="my-4">
          <InputComp
            title={"Address"}
            type={"text"}
            asterisk={true}
            name={"address"}
            placeholder={"Address"}
            maxLength={"120"}
            register={register}
            multiline={false}
            value={props.personalInfo.address}
            setValue={(value) =>
              props.onADDPERSONALINFO({
                ...props.personalInfo,
                address: value,
              })
            }
            error={errors.address ? true : false}
            errorMessage={errors.address ? errors.address.message : null}
          />
        </div>

        <InputComp
          title={"Objective"}
          type={"text"}
          asterisk={true}
          name={"objective"}
          placeholder={"Objective"}
          register={register}
          multiline={true}
          value={props.personalInfo.objective}
          setValue={(value) => {
            if (value.length <= 300) {
              props.onADDPERSONALINFO({
                ...props.personalInfo,
                objective: value,
              });
            }
          }}
          // error={errors.objective ? true : false}
          error={!!errors.objective}
          errorMessage={errors.objective ? errors.objective.message : null}
        />
        {props.personalInfo.objective.length >= 300 && (
          <div style={{ color: "red", marginTop: "5px", fontSize: "smaller" }}>
            You cannot add more than 300 characters.
          </div>
        )}

        <Divider className="personal-details-divider" />
        <BackNextBtnComp
          loading={loading}
          tab={props.tab}
          nextTitle={"Next"}
          backTitle={"Back"}
        />
      </form>
    </Paper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoComp);
