import React from "react";
import "../assets/Styles/TemplateEducationComponent.css";


const TemplateEducationComp = (props) => {
    return (
        <>
            {props.education && props.education.length > 0 ? (
                <div style={{ height: '120px', overflowY: 'auto' }}>
                    {props.education
                        // .filter((experience) => !experience.deleted)
                        .map((education, index) => (
                            <>
                                <h3 key={index} className="template-education-details pb-1">

                                    {education.degree} from {education.university}

                                    <span className="education-start-end">
                                        ({education.start_year}-{education.end_year})
                                    </span>
                                    <span className="template-education-university">
                                        {education.domain}
                                    </span>
                                </h3></>
                        ))
                    }
                </div>
            ) : (
                ""
            )}
        </>
    )
}

export default TemplateEducationComp; 