import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdBarChart, MdPerson, MdHome, MdOutlinePayment } from "react-icons/md";
import { GrTemplate } from "react-icons/gr";
// Admin Imports
// import MainDashboard from 'views/admin/default';
import MainDashboard from "./views/admin/default";
import "./views/admin/default";
// import NFTMarketplace from 'views/admin/marketplace';
import Profile from "./views/admin/profile";
import MyResume from "./views/admin/MyResume";
import Templates from "./views/admin/Templates";
import PricingPlan from "./views/admin/PricingPlan";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaMedal } from "react-icons/fa";
import MembershipTransaction from "./views/admin/MembershipTransaction";

const routes = [
  {
    name: "Dashboard",
    layout: "/member",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },

  {
    name: "Profile",
    layout: "/member",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <Profile />,
  },

  {
    name: "Membership Plan",
    layout: "/member",
    icon: <Icon as={FaMedal} width="20px" height="20px" color="inherit" />,
    path: "/pricingplan",
    component: <PricingPlan />,
    children: {
      // name: 'Membership Plan',
      layout: "/member",
      path: "/membershiptransaction",
      component: <MembershipTransaction />,
    },
  },

  {
    name: "My Resume",
    layout: "/member",
    icon: (
      <Icon
        as={IoDocumentTextOutline}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/myresume",
    component: <MyResume />,
  },

  {
    name: "Templates",
    layout: "/member",
    icon: <Icon as={GrTemplate} width="20px" height="20px" color="inherit" />,
    path: "/templates",
    component: <Templates />,
  },

  {
    name: "Transactions",
    layout: "/member",
    icon: (
      <Icon as={MdOutlinePayment} width="20px" height="20px" color="inherit" />
    ),
    path: "/transactions",
    component: <MembershipTransaction />,
  },
];

export default routes;
