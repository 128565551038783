import * as resumeAPI from "../endpoints/resume";

const createResume = async (fData) => {
  try {
    const response = await resumeAPI.create_Resume(fData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getResume = async () => {
  try {
    const response = await resumeAPI.get_resume();
    return response.data;
  } catch (error) {
    throw error;
  }
};

const get_ResumeEditData = async (id) => {
  try {
    const response = await resumeAPI.get_Resume_EditData(id);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const editResume = async (id, fData) => {
  try {
    const response = await resumeAPI.edit_Resume(id, fData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const Get_ResumeWith_uuid = async (uuid) => {
  try {
    const response = await resumeAPI.get_resume_with_uuid(uuid);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const Update_resume_Name = async (id, data) => {
  try {
    const response = await resumeAPI.update_resume(id, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  get_ResumeEditData,
  createResume,
  getResume,
  editResume,
  Get_ResumeWith_uuid,
  Update_resume_Name,
};
