import React, { useEffect, useState } from "react";
// import NavBar from '../Components/NavBar/NavBar';
import NavBar from "../Components/NewNavbar/NavBar";
import FillDetailsSidebar from "../Components/FillDetailsSidebar";
import WorkExperienceComponent from "../Components/WorkExpComp";
import EducationComp from "../Components/EducationComp";
import "../assets/Styles/Fill_Details.css";
import KeySkillComp from "../Components/keySkillComp";
import PersonalInfoComp from "../Components/PersonalInfoComp";
import PreviewComp from "../Components/PreviewComp";

const FillDetails = (props) => {
  const [tab, setTab] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className="fillDetails">
        {/* {tab === 4 ? null : ( */}
        <>
          {!isMobile || !showPreview ? (
            <FillDetailsSidebar tab={tab} setTab={setTab} />
          ) : null}

          {isMobile && (
            <div className="col-10 mt-3 mx-4">
              <button
                className="btn"
                style={{
                  padding: "5px",
                  backgroundColor: "#951dc1",
                  color: "white",
                  fontSize: "14px",
                }}
                onClick={() => setShowPreview(!showPreview)}
              >
                {showPreview ? "Back to edit" : "Show Preview"}
              </button>
            </div>
          )}

          <div className="row mx-3">
            {!isMobile || !showPreview ? (
              <div className="col-lg-6 col-12 mb-3 details-filling">
                {tab === 0 && <PersonalInfoComp setTab={setTab} tab={tab} />}
                {tab === 3 && <KeySkillComp setTab={setTab} tab={tab} />}
                {tab === 1 && (
                  <WorkExperienceComponent setTab={setTab} tab={tab} />
                )}
                {tab === 2 && <EducationComp setTab={setTab} tab={tab} />}
              </div>
            ) : null}

            {(isMobile && showPreview) || !isMobile ? (                                                 
              <div className="col-lg-6  col-12">
                <PreviewComp setTab={setTab} tab={tab} />
              </div>
            ) : null}
          </div>
        </>
        {/* )} */}
      </div>
    </>
  );
};
export default FillDetails;
