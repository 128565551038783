/* eslint-disable */

import {
  Box,
  Flex,
  HStack,
  Icon,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Select,
  Spinner,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
// import Card from 'components/card/Card';
import Card from "../../../../components_sec/card/Card";
import Menu from "../../../../components_sec/menu/MainMenu";
import * as React from "react";
// Assets
import {
  MdCancel,
  MdCheckCircle,
  MdContentCopy,
  MdDelete,
  MdOutlineError,
  MdPreview,
} from "react-icons/md";
import moment from "moment";
import ReactPaginate from "react-paginate";
import {
  SELECTTEMPLATE,
  ADDEXPERIENCEALL,
  ADDEDUCATION,
  ADDPERSONALINFO,
  EDITSKILL,
  SELECTRESUME,
} from "../../../../Redux/Actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import JsPDF from "jspdf";
import { BsEye, BsFiletypeDocx, BsThreeDots } from "react-icons/bs";
import { BsFiletypeTxt } from "react-icons/bs";
import { TiArrowDownOutline } from "react-icons/ti";
import { MdOutlinePlaylistAddCheckCircle } from "react-icons/md";
import { SlPencil } from "react-icons/sl";
import { FaPlus } from "react-icons/fa6";
import DeleteResumeWarningMdl from "../../../../Components/MyResume/DeleteResumeWarningMdl";
import {
  CloneResume,
  handleDeleteResume,
} from "../../../../api/services/memberDashboardService";
import DeleteTableDataWarningMdl from "../../MyResume/components/DeleteTableDataWarningMdl";
import { toast } from "react-toastify";
import { get_Dashboard_Data } from "../../../../api/endpoints/memberDashboard";
import axios from "axios";
import { Update_resume_Name } from "../../../../api/services/resumeService";
import { BiEdit } from "react-icons/bi";

const columnHelper = createColumnHelper();

const mapStateToProps = (state) => ({
  selectedTemplateId: state.selectedTemplateReducer.selectedTemplateId,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedTemplateId: (id) => dispatch(SELECTTEMPLATE(id)),
  setSelectedResumeId: (id) => dispatch(SELECTRESUME(id)),
  onADDPERSONALINFO: (details) => dispatch(ADDPERSONALINFO(details)),
  setADDEXPERIENCEALL: (experiences) => dispatch(ADDEXPERIENCEALL(experiences)),
  onADDEDUCATION: (details) => dispatch(ADDEDUCATION(details)),
  onEDITSKILL: (skills) => dispatch(EDITSKILL(skills)),
});

// const columns = columnsDataCheck;
function ComplexTable(props) {
  const { tableData, dashboardData, currentPage, setCurrentPage, loading, typeResume } =
    props;
  const [sorting, setSorting] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();

  const [popManage, setPopManage] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [deleteDataMdl, setDeleteDataMdl] = React.useState(false);
  const [deleteResumeId, setDeleteResumeId] = React.useState("");
  const [resumeName, setResumeName] = React.useState({
    first_name: "",
    last_name: "",
  });

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  let defaultData = tableData?.data;

  const domainName = window.location.hostname; // Get the domain name
  const protocol = window.location.protocol; // Get the protocol (http/https)
  const port = window.location.port; // Get the port (if specified)
  const fullUrl = `${protocol}//${domainName}${port ? `:${port}` : ""}`;

  const setUserData = (resume) => {
    props.onADDPERSONALINFO(resume.personalInfo);
    props.setADDEXPERIENCEALL(resume.experiences);
    props.onADDEDUCATION(resume.educationInfo);
    props.onEDITSKILL(resume.skills);
  };

  const navigateToFillDetails = (data) => {
    const updatedResumeData = {
      educationInfo: data?.education,
      experiences: data?.work_experience,
      skills: data?.skills?.map((val) => val?.skills),
      template_id: data?.template_id,
      id: data?.id,
      personalInfo: {
        address: data?.address,
        country: data?.country?.country_name,
        country_id: data?.country_id,
        city: data?.city?.city_name,
        city_id: data?.city_id,
        email: data?.email,
        firstName: data?.first_name,
        lastName: data?.last_name,
        phone: data?.phone,
        country_code: data?.country_code,
        objective: data?.objective,
        postalCode: data?.postal_code,
        profile: data?.profile,
        state: data?.state?.state_name,
        state_id: data?.state_id,
      },
    }
    props.setSelectedTemplateId(updatedResumeData?.template_id);
    props.setSelectedResumeId(updatedResumeData?.id);
    setUserData(updatedResumeData);
    // navigate("/MyResume");
    navigate("/template/FillDetails");

  };

  const downloadResume = (id) => {
    const report = new JsPDF("p", "pt", "a4");
    report.html(document.getElementById(`${id}report`)).then(() => {
      report.save(`resume.pdf`);
    });
  };

  const columns = [
    columnHelper.accessor("first_name", {
      id: "first_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          First Name
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("last_name", {
      id: "last_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Last Name
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("public_link", {
      id: "public_link",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Public Link
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Link to={`/publiclink/${info?.row?.original?.uuid}`} target="_blank">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue()
                ? `${fullUrl}/publiclink/${info?.row?.original?.uuid}`
                : "-"}
            </Text>
          </Link>
        </Flex>
      ),
    }),

    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          DATE
        </Text>
      ),
      cell: (info) => {
        // Format the 'created_at' value using moment.js
        const formattedDate = moment(info.getValue()).format("DD MMM YYYY");
        return (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {formattedDate}
          </Text>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Action
        </Text>
      ),
      cell: (info) => (
        <Box display="flex" gap={2}>
          <Button
            onClick={() => navigateToFillDetails(info.row.original)}
            colorScheme="blue"
            size="xs"
          >
            Builder
          </Button>

          <Button
            onClick={() => downloadResume("0")}
            colorScheme="green"
            size="xs"
          >
            Download PDF
          </Button>
        </Box>
      ),
    }),
  ];

  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(() => {
    setData(defaultData);
  }, [defaultData]);

  // Pagination Logic
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const buttonStyle = {
    padding: "8px 16px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "#ffffff",
    backgroundColor: "#3b82f6",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  };

  buttonStyle[":hover"] = {
    backgroundColor: "#2563eb",
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Uses 24-hour format
    });
  };

  const downloadNewResume = async (id) => {
    const element = document.getElementById(`resume-${id}`);

    if (!element) {
      console.error("Resume element not found!");
      return;
    }

    const pdf = new JsPDF("p", "pt", "a4");

    await pdf.html(element, {
      x: 10,
      y: 10,
      width: 500, // Adjust for A4 size
      windowWidth: element.scrollWidth,
      html2canvas: {
        scale: 2, // Increase resolution
        useCORS: true, // Fix external styles
      },
      callback: function (pdf) {
        pdf.save(`resume-${id}.pdf`);
      },
    });
  };

  const deleteResume = (resume) => {
    setDeleteDataMdl(true);
    setDeleteResumeId(resume?.id);
  };

  const handleDelete = async () => {
    try {
      const response = await handleDeleteResume(deleteResumeId);
      if (response?.success) {
        toast.success(response?.message, {
          position: "top-center",
        });
        setDeleteDataMdl(false);
        get_Dashboard_Data(currentPage);
        navigate(0);
      } else {
        toast.error(response?.error, {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClone_Resume = async (id) => {
    try {
      const response = await CloneResume(id);
      if (response?.success) {
        toast.success(response?.message, {
          position: "top-center",
        });
        get_Dashboard_Data(currentPage);
        navigate(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleResumeName = (e) => {
    const { name, value } = e.target;
    setResumeName((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditResumeName = async (id) => {
    try {
      const response = await Update_resume_Name(id, resumeName);

      if (response?.success) {
        setPopManage(false);
        navigate(0);
        get_Dashboard_Data(currentPage);
        toast.success(response?.message, {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  return (
    //   <Card
    //     flexDirection="column"
    //     w="100%"
    //     px="0px"
    //     overflowX={{ sm: "scroll", lg: "hidden" }} // Responsive scrolling for smaller screens
    //   >
    //     {/* <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
    //   <Text
    //     color={textColor}
    //     fontSize="22px"
    //     fontWeight="700"
    //     lineHeight="100%">
    //      Resume
    //   </Text>
    //   <Menu />
    // </Flex> */}
    //     <Box>
    //       {loading ? ( // Show spinner if loading is true
    //         <Flex justify="center" align="center" minH="200px">
    //           <Spinner size="lg" /> {/* Loader spinner */}
    //         </Flex>
    //       ) : (
    //         <Table variant="simple" color="gray.500" mb="24px" mt="12px">
    //           <Thead>
    //             {table.getHeaderGroups()?.map((headerGroup) => (
    //               <Tr key={headerGroup.id}>
    //                 {headerGroup?.headers?.map((header) => (
    //                   <Th
    //                     key={header.id}
    //                     colSpan={header.colSpan}
    //                     pe="10px"
    //                     borderColor={borderColor}
    //                     cursor="pointer"
    //                     onClick={header.column.getToggleSortingHandler()}>
    //                     <Flex
    //                       justifyContent="space-between"
    //                       align="center"
    //                       fontSize={{ sm: "10px", lg: "12px" }} // Font size varies by screen size
    //                       color="gray.400">
    //                       {flexRender(
    //                         header.column.columnDef.header,
    //                         header.getContext()
    //                       )}
    //                       {{
    //                         asc: "", // Indicate sort state
    //                         desc: "", // Placeholder for sorting icons if needed
    //                       }[header.column.getIsSorted()] ?? null}
    //                     </Flex>
    //                   </Th>
    //                 ))}
    //               </Tr>
    //             ))}
    //           </Thead>

    //           <Tbody>
    //             {table.getRowModel()
    //               .rows.slice(0, 11)
    //               .map((row) => {
    //                 return (
    //                   <Tr key={row.id}>
    //                     {row.getVisibleCells().map((cell) => {
    //                       return (
    //                         <Td
    //                           key={cell.id}
    //                           fontSize={{ sm: "14px" }}
    //                           minW={{ sm: "150px", md: "200px", lg: "auto" }}
    //                           borderColor="transparent">
    //                           {flexRender(
    //                             cell.column.columnDef.cell,
    //                             cell.getContext()
    //                           )}
    //                         </Td>
    //                       );
    //                     })}
    //                   </Tr>
    //                 );
    //               })}
    //           </Tbody>
    //         </Table>
    //       )}

    //       <HStack justify="center" spacing={4} mt={4}>
    //         {/* <Flex px="25px" mb="8px" alignItems="center">
    //       <Text mr="10px"  mb="0px" p="0px" color={textColor}>
    //         Show per row:
    //       </Text>
    //       <Select
    //         value={rowsPerPage}
    //         onChange={(e) => {
    //           setRowsPerPage(Number(e.target.value)); // Update rows per page
    //           // Reset current page to 1 when changing rows per page
    //           if (currentPage > Math.ceil(paginatedRows.length / Number(e.target.value))) {
    //             handlePrevPage(); // Go back to the previous page if needed
    //           }
    //         }}
    //         width="100px"
    //       >
    //         <option value={5}>5</option>
    //         <option value={10}>10</option>
    //         <option value={15}>15</option>
    //         <option value={20}>20</option>
    //       </Select>
    //     </Flex> */}

    //         <div>
    //           {tableData?.data?.length < tableData?.total && (
    //             <div className="pagination-container">
    //               <ReactPaginate
    //                 pageCount={Math.ceil(tableData?.total / tableData?.per_page)}
    //                 pageRangeDisplayed={1}
    //                 marginPagesDisplayed={2}
    //                 onPageChange={handlePageClick}
    //                 containerClassName="pagination"
    //                 activeClassName="selected"
    //                 disabledClassName="disabled"
    //               />
    //             </div>
    //           )}
    //         </div>
    //         {/* <Button onClick={handlePrevPage} isDisabled={currentPage === 1}>
    //           Previous
    //         </Button>
    //         <Text m="0px" p="0px" >
    //           Page {currentPage} of {totalPages}
    //         </Text>
    //         <Button onClick={handleNextPage} isDisabled={currentPage === totalPages}>
    //           Next
    //         </Button> */}
    //       </HStack>
    //     </Box>
    //   </Card>
    <>
      {deleteDataMdl && (
        <DeleteTableDataWarningMdl
          setDeleteDataMdl={setDeleteDataMdl}
          handleDelete={handleDelete}
        />
      )}

      {/* <div className="row" style={{ backgroundColor: "rgb(241 241 241 / 31%)" }}>
        {tableData?.data?.map((resume) => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "25px 15px",
              }}
              className="col-12 col-sm-12 col-md-6 col-lg-6"
            >
              <div
                style={{
                  display: "flex",
                  // alignItems: "center",
                  // border: "1px solid #ddd",
                  borderRadius: "10px",
                  padding: "15px",
                  width: "450px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    width: "200px",
                    height: "300px",
                    // backgroundColor: "#f0f0f0",
                    display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      color: "#333",
                      fontWeight: "bold",
                    }}
                  >
                    {`${resume?.first_name} ${resume?.last_name}`}
                  </span>
                </div>
                <div style={{ flex: 1, marginLeft: "20px" }}>
                  {popManage && selectedId == resume?.id ? (
                    <div style={{ marginBottom: "5px" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "50px",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="First Name"
                          name="first_name"
                          value={resumeName?.first_name}
                          onChange={handleResumeName}
                          style={{
                            border: "1px solid #ccc",
                            padding: "4px 8px",
                            outline: "none",
                            borderRight: "none",
                            borderRadius: "5px 0 0 5px",
                            width: '100px'
                          }}
                        />
                        <input
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                          value={resumeName?.last_name}
                          onChange={handleResumeName}
                          style={{
                            border: "1px solid #ccc",
                            padding: "4px 8px",
                            outline: "none",
                            borderRadius: "0 5px 5px 0",
                            width: '100px'
                          }}
                        />
                      </div>
                      <button
                        onClick={() => handleEditResumeName(resume?.id)}
                        style={{
                          padding: "5px",
                          marginTop: "4px",
                          backgroundColor: "rgb(111, 33, 183)",
                          color: "white",
                          fontSize: "14px",
                          fontWeight: "500",
                          borderRadius: "5px",
                        }}
                      >
                        Update
                      </button>
                    </div>
                  ) : (
                    <h3
                      style={{
                        margin: "0 0 5px 0",
                        fontSize: "18px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setPopManage(!popManage);
                        setSelectedId(resume?.id);
                      }}
                    >
                      {`${resume?.first_name} ${resume?.last_name}`}{" "}
                      <SlPencil size={20} color="rgb(159, 166, 187)" />
                    </h3>
                  )}

                  <p style={{ fontSize: "12px", color: "#777" }}>
                    Updated {formatDate(resume?.updated_at)}
                  </p>

                  <div style={{ marginTop: "10px" }}>
                    <p
                      style={{
                        color: "rgb(30, 37, 50)",
                        fontSize: "16px",
                        fontWeight: "600",
                        cursor: "pointer",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <BsEye size={24} color="#6f21b7" />
                      Views ({resume.views})
                    </p>
                    <p
                      style={{
                        color: "rgb(30, 37, 50)",
                        fontSize: "16px",
                        fontWeight: "600",
                        cursor: "pointer",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                      onClick={() => downloadNewResume(resume)}
                    >
                      <TiArrowDownOutline size={24} color="#6f21b7" />
                      Download PDF
                    </p>

                    <p
                      style={{
                        color: "rgb(30, 37, 50)",
                        fontSize: "16px",
                        fontWeight: "600",
                        cursor: "pointer",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                      onClick={() => handleClone_Resume(resume?.id)}
                    >
                      <MdContentCopy size={22} color="#6f21b7" />
                      Make a copy
                    </p>
                    <p
                      style={{
                        color: "rgb(30, 37, 50)",
                        fontSize: "16px",
                        fontWeight: "600",
                        cursor: "pointer",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                      onClick={() => navigateToFillDetails(resume)}
                    >
                      <BiEdit size={22} color="#6f21b7" />
                      Builder
                    </p>

                    <p
                      style={{
                        color: "rgb(30, 37, 50)",
                        fontSize: "16px",
                        fontWeight: "600",
                        cursor: "pointer",
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                      onClick={() => deleteResume(resume)}
                    >
                      <MdDelete size={22} color="#6f21b7" />
                      Delete
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </>
        ))}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "20px",
            borderRadius: "10px",
            width: "500px",
            // backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              width: "350px",
              height: "300px",
              border: "2px solid #e0e0e0",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: "#f5f7fb",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Link to={typeResume == 'resume' ? "/templateCard" : "/CvtemplateCard"}
                style={{ fontSize: "24px", color: "#c0c4d6" }}
              >
                +
              </Link>
            </div>
          </div>

          <div style={{ marginLeft: "20px" }}>
            <h2
              style={{
                margin: "0",
                color: "#5a6473",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              New Resume
            </h2>
            <p
              style={{
                margin: "10px 0 0",
                color: "#99a0aa",
                fontSize: "14px",
                lineHeight: "1.5",
              }}
            >
              Create a tailored resume for each job application. Double your
              chances of getting hired!
            </p>
          </div>
        </div>
      </div > */}
      <div className="row cplx-row">
        {tableData?.data?.map((resume) => (
          <div className="cplx-resume-card col-12 col-sm-12 col-md-6 col-lg-6" key={resume.id}>
            <div className="cplx-resume-card-container">
              <div className="cplx-resume-card-image">
                <span className="cplx-resume-card-name">{`${resume?.first_name} ${resume?.last_name}`}</span>
              </div>
              <div className="cplx-resume-card-content">
                {popManage && selectedId === resume?.id ? (
                  <div style={{ marginBottom: "5px" }}>
                    <div style={{ display: "flex", width: "50px" }}>
                      <input
                        type="text"
                        placeholder="First Name"
                        name="first_name"
                        value={resumeName?.first_name}
                        onChange={handleResumeName}
                        style={{ border: "1px solid #ccc", padding: "4px 8px", outline: "none", borderRight: "none", borderRadius: "5px 0 0 5px", width: "100px" }}
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="last_name"
                        value={resumeName?.last_name}
                        onChange={handleResumeName}
                        style={{ border: "1px solid #ccc", padding: "4px 8px", outline: "none", borderRadius: "0 5px 5px 0", width: "100px" }}
                      />
                    </div>
                    <button
                      onClick={() => handleEditResumeName(resume?.id)}
                      style={{ padding: "5px", marginTop: "4px", backgroundColor: "rgb(111, 33, 183)", color: "white", fontSize: "14px", fontWeight: "500", borderRadius: "5px" }}
                    >
                      Update
                    </button>
                  </div>
                ) : (
                  <h3 className="cplx-resume-card-title" onClick={() => { setPopManage(!popManage); setSelectedId(resume?.id); }}>
                    {`${resume?.first_name} ${resume?.last_name}`} <SlPencil size={20} color="rgb(159, 166, 187)" />
                  </h3>
                )}

                <p className="cplx-resume-card-updated">Updated {formatDate(resume?.updated_at)}</p>

                <div className="cplx-resume-card-actions">
                  <p className="cplx-resume-card-action-item">
                    <BsEye size={24} color="#6f21b7" /> Views ({resume.views})
                  </p>
                  <p className="cplx-resume-card-action-item" onClick={() => downloadNewResume(resume)}>
                    <TiArrowDownOutline size={24} color="#6f21b7" /> Download PDF
                  </p>
                  <p className="cplx-resume-card-action-item" onClick={() => window.open(`${fullUrl}/publiclink/${resume?.uuid}`, "_blank")}>
                    <MdPreview size={22} color="#6f21b7" /> Preview
                  </p>
                  <p className="cplx-resume-card-action-item" onClick={() => handleClone_Resume(resume?.id)}>
                    <MdContentCopy size={22} color="#6f21b7" /> Make a copy
                  </p>
                  <p className="cplx-resume-card-action-item" onClick={() => navigateToFillDetails(resume)}>
                    <BiEdit size={22} color="#6f21b7" /> Builder
                  </p>

                  <p className="cplx-resume-card-action-item" onClick={() => deleteResume(resume)}>
                    <MdDelete size={22} color="#6f21b7" /> Delete
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="cplx-resume-card-new">
          <div className="cplx-resume-card-new-box">
            <div className="cplx-resume-card-add-btn">
              <Link to={typeResume === "resume" ? "/templateCard" : "/CvtemplateCard"}>+</Link>
            </div>
          </div>
          <div className="cplx-resume-card-new-text">
            <h2 className="cplx-resume-card-new-title">New Resume</h2>
            <p className="cplx-resume-card-new-description">
              Create a tailored resume for each job application. Double your chances of getting hired!
            </p>
          </div>
        </div>
      </div>

      <div>
        {tableData?.data?.length < tableData?.total && (
          <div className="pagination-container">
            <ReactPaginate
              pageCount={Math.ceil(tableData?.total / tableData?.per_page)}
              pageRangeDisplayed={1}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="selected"
              disabledClassName="disabled"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplexTable);
