import { Button } from "@mui/material";
import { IoWarningOutline } from "react-icons/io5";

const DeleteResumeWarningMdl = ({ setDeleteDataMdl, handleDelete }) => {
  return (
    <>
      <div className="warningModal">
        <div className="warning-mdl">
          {/* <span className="warning-close-btn" >&times;</span> */}
          <div className="d-flex justify-content-center mb-3">
            <IoWarningOutline color="#ffcc00" size={40} />
          </div>
          <h4 className="text-center warning-text">
            Are you sure you want to delete this selected resume?
          </h4>
          <div className="d-flex gap-2 mt-4 justify-content-end">
            <Button onClick={() => setDeleteDataMdl(false)}>No</Button>

            <Button className="delete-yes-btn" onClick={handleDelete}>
              Yes
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteResumeWarningMdl;
