
import React, { useState, useEffect } from "react";
import { Divider, MenuItem, Paper, Select, Button } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { connect } from "react-redux";
import { ADDEDUCATION } from "../Redux/Actions";
import { useForm, Controller } from "react-hook-form";
import "../assets/Styles/EducationComp.css";
import BackNextBtnComp from "./BackNextBtnComp";
import InputComp from "./InputComp";
import SelectComp from "./SelectComp";

const currentYear = new Date().getFullYear();
const Years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) => 1950 + i);


const mapStateToProp = (state) => ({
  educationInfo: state.EducationInfo.educationInfo, // Saved education data from Redux
});

const mapDispatchToProps = (dispatch) => ({
  onAddEducation: (details) => dispatch(ADDEDUCATION(details)),
});

const EducationComp = (props) => {
  const [loading, setLoading] = useState(false);
  const [educationList, setEducationList] = useState([
    { domain: "", university: "", degree: "", start_year: "", end_year: currentYear },
  ]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      educationList: props.educationInfo || [
        { domain: "", university: "", degree: "", start_year: "", end_year: currentYear },
      ],
    },
    shouldUnregister: false,   // Keeps values when unmounting
    mode: "onBlur",            // Validate onBlur instead of onChange
    reValidateMode: "onChange",
  });
  // Initialize educationList with saved data from Redux (if available)
  useEffect(() => {
    if (props.educationInfo && props.educationInfo.length > 0) {
      setEducationList(props.educationInfo); // Set saved education data
    }
  }, [props.educationInfo]);

  const handleNext = (data) => {
    setLoading(true);
    props.onAddEducation(educationList);

    setTimeout(() => {
      setLoading(false);
      props.setTab(props.tab + 1); // Navigate to the next tab
    }, 1000);
  };

  const handleBack = () => {
    props.setTab(props.tab - 1);
  };

  // Handle adding a new education entry
  const addNewEducation = () => {
    setEducationList([
      ...educationList,
      { domain: "", university: "", degree: "", start_year: "", end_year: "" },
    ]);
  };

  const deleteEducation = (index) => {
    setEducationList(educationList?.filter((_, i) => i !== index));
  }

  // Handle changes to individual education entry
  const updateEducation = (index, key, value) => {
    const updatedList = educationList.map((education, i) =>
      i === index ? { ...education, [key]: value } : education
    );
    setEducationList(updatedList);
  };

  return (
    <Paper className="EducationPaper col-9" elevation={3}>
      <h2 className="education-heading">Education Details</h2>
      {/* <Divider sx={{ margin: "10px 0px" }} /> */}
      <form onSubmit={handleSubmit(handleNext)}>
        {educationList.map((education, index) => (
          <>
            <h3 className="experience-heading mt-3">Education {index + 1}</h3>
            <div className="education-form-1">
              <div key={index} className="education-form py-3 px-2 mt-4">
                {/* <InputComp
                  title={"University"}
                  type={"text"}
                  asterisk={true}
                  name={`university-${index}`}
                  placeholder={"University"}
                  maxLength={"30"}
                  register={register}
                  multiline={false}
                  value={education.university}
                  setValue={(value) => updateEducation(index, "university", value)}
                  error={errors[`university-${index}`] ? true : false}
                  errorMessage={
                    errors[`university-${index}`]
                      ? errors[`university-${index}`].message
                      : null
                  }
                /> */}

                <InputComp
                  title={"University"}
                  type={"text"}
                  asterisk={true}
                  name={`educationList.${index}.university`}
                  placeholder={"University"}
                  maxLength={"30"}
                  register={register}
                  multiline={false}
                  value={education.university}
                  setValue={(value) => updateEducation(index, "university", value)}
                  error={errors?.educationList?.[index]?.university ? true : false}
                  errorMessage={errors?.educationList?.[index]?.university?.message || null}
                />


                {/* <InputComp
                  title={"Degree"}
                  type={"text"}
                  asterisk={true}
                  name={`degree-${index}`}
                  placeholder={"Degree"}
                  maxLength={"30"}
                  register={register}
                  multiline={false}
                  value={education.degree}
                  setValue={(value) => updateEducation(index, "degree", value)}
                  error={errors[`degree-${index}`] ? true : false}
                  errorMessage={
                    errors[`degree-${index}`]
                      ? errors[`degree-${index}`].message
                      : null
                  }
                /> */}
                <InputComp
                  title={"Degree"}
                  type={"text"}
                  asterisk={true}
                  name={`educationList.${index}.degree`}
                  placeholder={"Degree"}
                  maxLength={"30"}
                  register={register}
                  multiline={false}
                  value={education.degree}
                  setValue={(value) => updateEducation(index, "degree", value)}
                  error={errors?.educationList?.[index]?.degree ? true : false}
                  errorMessage={errors?.educationList?.[index]?.degree?.message || null}
                />

                {/* <SelectComp
                  title={"Start Year"}
                  asterisk={true}
                  errorMessage={
                    errors[`start_year-${index}`]
                      ? errors[`start_year-${index}`].message
                      : null
                  }
                  error={errors[`start_year-${index}`] ? true : false}
                >
                  <Controller
                    name={`start_year-${index}`}
                    control={control}
                    rules={{
                      required: "*Please Select Start Year",
                      validate: (value) =>
                        !value ||
                        value <= getValues(`end_year-${index}`) ||
                        "*Start year must be before or the same as end year",
                    }}
                    defaultValue={education.start_year}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={education.start_year}
                        onChange={(e) => {
                          updateEducation(index, "start_year", e.target.value);
                          field.onChange(e);
                        }}
                        error={!!errors[`start_year-${index}`]}>
                        <MenuItem value="" disabled>
                          Select a start year
                        </MenuItem>
                        {Years?.map((Year, idx) => (
                          <MenuItem key={idx} value={Year}>
                            {Year}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </SelectComp> */}
                <SelectComp
                  title={"Start Year"}
                  asterisk={true}
                  errorMessage={
                    errors?.educationList?.[index]?.start_year?.message || null
                  }
                  error={!!errors?.educationList?.[index]?.start_year}
                >
                  <Controller
                    name={`educationList.${index}.start_year`}
                    control={control}
                    rules={{
                      required: "*Please Select Start Year",
                      validate: (value) =>
                        !value ||
                        value <= getValues(`educationList.${index}.end_year`) ||
                        "*Start year must be before or the same as end year",
                    }}
                    defaultValue={education.start_year}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={education.start_year}
                        onChange={(e) => {
                          updateEducation(index, "start_year", e.target.value);
                          field.onChange(e);
                        }}
                        error={!!errors?.educationList?.[index]?.start_year}
                      >
                        <MenuItem value="" disabled>
                          Select a start year
                        </MenuItem>
                        {Years?.map((Year, idx) => (
                          <MenuItem key={idx} value={Year}>
                            {Year}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </SelectComp>

                {/* <SelectComp
                  title={"End Year"}
                  asterisk={true}
                  errorMessage={
                    errors[`end_year-${index}`]
                      ? errors[`end_year-${index}`].message
                      : null
                  }
                  error={errors[`end_year-${index}`] ? true : false}>
                  <Controller
                    name={`end_year-${index}`}
                    control={control}
                    rules={{
                      required: "*Please Select End Year",
                      validate: (value) =>
                        !value ||
                        value >= getValues(`start_year-${index}`) ||
                        "*End year must be after or the same as start year",
                    }}
                    defaultValue={education.end_year}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={education.end_year}
                        onChange={(e) => {
                          updateEducation(index, "end_year", e.target.value);
                          field.onChange(e);
                        }}
                        error={!!errors[`end_year-${index}`]}
                      >
                        <MenuItem value="" disabled>
                          Select an end year
                        </MenuItem>
                        {Years?.map((Year, idx) => (
                          <MenuItem key={idx} value={Year}>
                            {Year}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </SelectComp> */}

                <SelectComp
                  title={"End Year"}
                  asterisk={true}
                  errorMessage={
                    errors?.educationList?.[index]?.end_year?.message || null
                  }
                  error={!!errors?.educationList?.[index]?.end_year}
                >
                  <Controller
                    name={`educationList.${index}.end_year`} // ✅ Correct naming
                    control={control}
                    rules={{
                      required: "*Please Select End Year",
                      validate: (value) =>
                        !value ||
                        value >= getValues(`educationList.${index}.start_year`) ||
                        "*End year must be after or the same as start year",
                    }}
                    defaultValue={education.end_year} // ✅ Ensure defaultValue is set
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={education.end_year}
                        onChange={(e) => {
                          updateEducation(index, "end_year", e.target.value);
                          field.onChange(e);
                        }}
                        error={!!errors?.educationList?.[index]?.end_year}
                      >
                        <MenuItem value="" disabled>
                          Select an end year
                        </MenuItem>
                        {Years?.map((Year, idx) => (
                          <MenuItem key={idx} value={Year}>
                            {Year}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </SelectComp>



                {/* <InputComp
                  title={"Domain"}
                  type={"text"}
                  asterisk={true}
                  name={`domain-${index}`}
                  placeholder={"Domain"}
                  register={register}
                  multiline={false}
                  value={education.domain}
                  setValue={(value) => updateEducation(index, "domain", value)}
                  error={errors[`domain-${index}`] ? true : false}
                  errorMessage={
                    errors[`domain-${index}`]
                      ? errors[`domain-${index}`].message
                      : null
                  }
                /> */}

                <InputComp
                  title={"Domain"}
                  type={"text"}
                  asterisk={true}
                  name={`educationList.${index}.domain`}
                  placeholder={"Domain"}
                  maxLength={"30"}
                  register={register}
                  multiline={false}
                  value={education.domain}
                  setValue={(value) => updateEducation(index, "domain", value)}
                  error={errors?.educationList?.[index]?.domain ? true : false}
                  errorMessage={errors?.educationList?.[index]?.domain?.message || null}
                />


                {index !== 0 && (
                  <div className="d-flex justify-content-end mt-0">
                    <DeleteOutlineOutlinedIcon
                      sx={{ marginLeft: "0px", marginTop: "35px", color: "#f14747", cursor: 'pointer' }}
                      onClick={() => deleteEducation(index)}
                    />
                  </div>
                )}

                {/* <Divider sx={{ margin: "10px 0px" }} /> */}
              </div>
            </div>
          </>
        ))}

        {educationList.length === 10 ? null : (
          <div className="add-new-btn-cont">
            <Button
              variant="text"
              className="add-new-btn-cont"
              color="primary"
              onClick={addNewEducation}
              type="button"
              style={{ marginBottom: "20px" }}
            >
              Add New Education
            </Button>
          </div>
        )}

        <BackNextBtnComp
          onNext={handleSubmit(handleNext)}
          onBack={handleBack}
          loading={loading}
          tab={props.tab}
          nextTitle={"Next"}
          backTitle={"Back"}
        />
      </form>
    </Paper>
  );
};

export default connect(mapStateToProp, mapDispatchToProps)(EducationComp);


// import React, { useState, useEffect } from "react";
// import { Divider, MenuItem, Paper, Select, Button } from "@mui/material";
// import { connect } from "react-redux";
// import { ADDEDUCATION } from "../Redux/Actions";
// import { useForm, Controller } from "react-hook-form";
// import "../assets/Styles/EducationComp.css";
// import BackNextBtnComp from "./BackNextBtnComp";
// import InputComp from "./InputComp";
// import SelectComp from "./SelectComp";

// const currentYear = new Date().getFullYear();
// const Years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) => 1950 + i);

// const mapStateToProps = (state) => ({
//   educationInfo: state.EducationInfo.educationInfo, // Saved education data from Redux
// });

// const mapDispatchToProps = (dispatch) => ({
//   onAddEducation: (details) => dispatch(ADDEDUCATION(details)),
// });

// const EducationComp = (props) => {
//   const [loading, setLoading] = useState(false);
//   const [educationList, setEducationList] = useState([
//     { domain: "", university: "", degree: "", start_year: "", end_year: currentYear },
//   ]);

//   const {
//     register,
//     handleSubmit,
//     control,
//     formState: { errors },
//     setValue,
//     watch,
//     trigger,
//   } = useForm();

//   // Sync Redux stored education data on mount
//   useEffect(() => {
//     if (props.educationInfo?.length > 0) {
//       setEducationList(props.educationInfo);
//       props.educationInfo.forEach((edu, index) => {
//         setValue(`domain-${index}`, edu.domain);
//         setValue(`university-${index}`, edu.university);
//         setValue(`degree-${index}`, edu.degree);
//         setValue(`start_year-${index}`, edu.start_year);
//         setValue(`end_year-${index}`, edu.end_year);
//       });
//     }
//   }, [props.educationInfo, setValue]);

//   // Sync form with state updates
//   useEffect(() => {
//     trigger(); // Trigger validation when educationList updates
//   }, [educationList, trigger]);

//   const handleNext = () => {
//     setLoading(true);
//     props.onAddEducation(educationList);

//     setTimeout(() => {
//       setLoading(false);
//       props.setTab(props.tab + 1);
//     }, 1000);
//   };

//   const handleBack = () => {
//     props.setTab(props.tab - 1);
//   };

//   const addNewEducation = () => {
//     setEducationList([
//       ...educationList,
//       { domain: "", university: "", degree: "", start_year: "", end_year: "" },
//     ]);
//   };

//   const updateEducation = (index, key, value) => {
//     const updatedList = educationList.map((education, i) =>
//       i === index ? { ...education, [key]: value } : education
//     );
//     setEducationList(updatedList);
//     setValue(`${key}-${index}`, value); // Update react-hook-form state
//   };

//   return (
//     <Paper className="EducationPaper col-9" elevation={3}>
//       <h2 className="education-heading">Education Details</h2>
//       <Divider sx={{ margin: "10px 0px" }} />
//       <form onSubmit={handleSubmit(handleNext)}>
//         {educationList.map((education, index) => {
//           const watchStartYear = watch(`start_year-${index}`);

//           return (
//             <div key={index} className="education-form">
//               <h3 className="experience-heading mt-3">Education {index + 1}</h3>
//               <InputComp
//                 title="Domain"
//                 type="text"
//                 asterisk={true}
//                 name={`domain-${index}`}
//                 placeholder="Domain"
//                 register={register}
//                 value={education.domain}
//                 setValue={(value) => updateEducation(index, "domain", value)}
//                 error={!!errors[`domain-${index}`]}
//                 errorMessage={errors[`domain-${index}`]?.message}
//               />
//               <InputComp
//                 title="University"
//                 type="text"
//                 asterisk={true}
//                 name={`university-${index}`}
//                 placeholder="University"
//                 register={register}
//                 value={education.university}
//                 setValue={(value) => updateEducation(index, "university", value)}
//                 error={!!errors[`university-${index}`]}
//                 errorMessage={errors[`university-${index}`]?.message}
//               />
//               <InputComp
//                 title="Degree"
//                 type="text"
//                 asterisk={true}
//                 name={`degree-${index}`}
//                 placeholder="Degree"
//                 register={register}
//                 value={education.degree}
//                 setValue={(value) => updateEducation(index, "degree", value)}
//                 error={!!errors[`degree-${index}`]}
//                 errorMessage={errors[`degree-${index}`]?.message}
//               />
//               <SelectComp title="Start Year" asterisk={true}>
//                 <Controller
//                   name={`start_year-${index}`}
//                   control={control}
//                   rules={{ required: "*Please Select Start Year" }}
//                   defaultValue={education.start_year}
//                   render={({ field }) => (
//                     <Select
//                       {...field}
//                       value={education.start_year}
//                       onChange={(e) => updateEducation(index, "start_year", e.target.value)}
//                       error={!!errors[`start_year-${index}`]}
//                     >
//                       <MenuItem value="" disabled>Select a start year</MenuItem>
//                       {Years.map((Year) => (
//                         <MenuItem key={Year} value={Year}>{Year}</MenuItem>
//                       ))}
//                     </Select>
//                   )}
//                 />
//               </SelectComp>
//               <SelectComp title="End Year" asterisk={true}>
//                 <Controller
//                   name={`end_year-${index}`}
//                   control={control}
//                   rules={{
//                     required: "*Please Select End Year",
//                     validate: (value) =>
//                       !value || value >= watchStartYear || "*End year must be after or the same as start year",
//                   }}
//                   defaultValue={education.end_year}
//                   render={({ field }) => (
//                     <Select
//                       {...field}
//                       value={education.end_year}
//                       onChange={(e) => updateEducation(index, "end_year", e.target.value)}
//                       error={!!errors[`end_year-${index}`]}
//                     >
//                       <MenuItem value="" disabled>Select an end year</MenuItem>
//                       {Years.map((Year) => (
//                         <MenuItem key={Year} value={Year}>{Year}</MenuItem>
//                       ))}
//                     </Select>
//                   )}
//                 />
//               </SelectComp>
//               <Divider sx={{ margin: "10px 0px" }} />
//             </div>
//           );
//         })}
//         <Button
//           variant="text"
//           className="add-new-btn-cont"
//           color="primary"
//           onClick={addNewEducation}
//           style={{ marginBottom: "20px" }}
//         >
//           Add New Education
//         </Button>
//         <BackNextBtnComp
//           onNext={handleSubmit(handleNext)}
//           onBack={handleBack}
//           loading={loading}
//           tab={props.tab}
//           nextTitle="Next"
//           backTitle="Back"
//         />
//       </form>
//     </Paper>
//   );
// };

// export default connect(mapStateToProps, mapDispatchToProps)(EducationComp);
