import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../../src/assets/Styles/Blog.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import img1 from '../../assets/Images/ResumeFormats.avif'

import { MdOutlineWatchLater } from "react-icons/md";
import { Link } from "react-router-dom";

import React from "react";
import { IoEye } from "react-icons/io5";

const Blog = () => {
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([]);
  const [popular, setPopular] = useState([]);
  const [breakingNews, setBreakingNews] = useState([]);

  const [browse_All, setBrowse_All] = useState({});
  const [isLoading, setIsLoading] = useState(true);



  const getAllBlogs = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(
        "https://resumecv.idea2reality.tech/api/get_blog"
      );
      setBlogs(response?.data?.data);
      setPopular(response?.data?.popular);
      setBreakingNews(response?.data?.breaking_news);
    } catch (error) {
      console.error("Error fetching all blogs:", error.message);
    } finally {
      setIsLoading(false)
    }
  };

  const getBrowseAll_blogs = async () => {
    try {
      const AllBlogs = await axios.get(
        "https://resumecv.idea2reality.tech/api/get_all_blogs?page=1"
      );
      setBrowse_All(AllBlogs.data.data);

    } catch (error) {
      console.error("Error fetching all blogs:", error.message);
    }
  }

  useEffect(() => {
    getAllBlogs();
    getBrowseAll_blogs();
  }, []);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <>

      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: "center", textAlign: "center", padding: "20px" }}>
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div className="container py-3">
            <div className="news-section-3 py-4">
              <div className="">
                <h1>The Elevator</h1>
                <p style={{ fontSize: '20px', fontWeight: '400' }}>A blog to elevate your resume, job search & <br /> career to the top floor!</p>
              </div>

              <Swiper
                className=""
                modules={[Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                autoplay={true}
                loop={true}
                pagination={{
                  clickable: true,
                }}
              >
                {blogs?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Link to={`/blog-details/${item?.id}`}>
                      <div className="weekly-review">
                        <img
                          className="weekly-reviews-img"
                          src={`https://resumecv.idea2reality.tech/storage/thumbnail_image/${blogs[0]?.thumbnail_image}`}
                        />

                        <div className="text-block" style={{ backgroundColor: '#000000c9', borderRadius: '10px' }}>
                          <span>{item?.category?.name}</span>
                          <h3 className="news-text1">{item?.title}.</h3>
                          <p className="m-0 d-flex align-items-center gap-2"> <IoEye size={20} /> {item?.views}</p>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="container" style={{ marginBottom: "15%" }}>
            <h1>Articles</h1>
            <div className="border-bottom"></div>

            <div className="blog-parent py-5">
              <div className="blog-child1">
                <Link to={`/blog-details/${breakingNews[0]?.id}`}>
                  {/* <img src={`https://resumecv.idea2reality.tech/storage/thumbnail_image/${blogs[0]?.thumbnail_image
                            }`} /> */}
                  <div className="blog-overlay-text">
                    <p>
                      Written by <span>{breakingNews[0]?.news_reporter}</span>
                    </p>
                    <h3>
                      <Link
                        to={`/blog-details/${breakingNews[0]?.id}`}
                        style={{ textDecoration: "none", color: "#000" }}
                        onMouseEnter={(e) => (e.target.style.color = "#ff5733")}
                        onMouseLeave={(e) => (e.target.style.color = "#000")}
                      >
                        {breakingNews[0]?.title}
                      </Link>
                    </h3>
                    <p className="d-flex gap-2">
                      <MdOutlineWatchLater size={20} /> {breakingNews[0]?.read_time} min
                    </p>
                  </div>

                  <div
                    className="position-absolute"
                    style={{ bottom: "15px", right: "15px" }}
                  >
                    <img src={img1} alt="no-Image" />
                  </div>
                </Link>
              </div>
              <div className="blog-child2">
                <h3>Popular</h3>

                {popular?.map((item) => {
                  return (
                    <>
                      <div>
                        <img src={`https://resumecv.idea2reality.tech/storage/thumbnail_image/${item?.thumbnail_image
                          }`} />
                        <span className="">{item?.category?.name}</span>
                        <p>
                          {" "}
                          <Link
                            to={`/blog-details/${item?.id}`}
                            style={{ textDecoration: "none", color: "#000" }}
                            onMouseEnter={(e) => (e.target.style.color = "#ff5733")}
                            onMouseLeave={(e) => (e.target.style.color = "#000")}
                          >
                            {item?.title}{" "}
                          </Link>
                        </p>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="blog-child3">
                {breakingNews?.length > 0 ? <h3>Latest</h3> : <h3>No Latest News</h3>}

                {breakingNews?.slice(1)
                  ?.map((item) => {
                    return (
                      <>
                        <div>
                          <span>{formatDate(item?.news_date)}</span>
                          <Link to={`/blog-details/${item?.id}`}>
                            <p
                              dangerouslySetInnerHTML={{ __html: item?.title }}
                              style={{ textDecoration: "none", color: "#000" }}
                              onMouseEnter={(e) => (e.target.style.color = "#ff5733")}
                              onMouseLeave={(e) => (e.target.style.color = "#000")}
                            ></p>
                          </Link>
                        </div>
                        <div className="border-bottom mb-4"></div>
                      </>
                    );
                  })}

              </div>
            </div>
          </div>

          <div className="container" style={{ marginBottom: "15%" }}>
            <h1>Browse all</h1>
            <div className="border-bottom"></div>

            <div className="browse-grid-container mt-5">
              {browse_All?.data?.map((item, index) => (
                <div className="browse-card" key={index}>
                  <Link to={`/blog-details/${item?.id}`} >
                    <img
                      src={`https://resumecv.idea2reality.tech/storage/thumbnail_image/${item?.thumbnail_image
                        }`}
                      alt=""
                      className="browse-card-image"
                    />
                  </Link>
                  <div className="browse-card-content">
                    <p className="browse-card-meta">
                      Article • Resume Help • {item?.read_time} min read
                    </p>
                    <Link to={`/blog-details/${item?.id}`} className="browse-card-title">
                      {item?.title}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}


    </>
  );
};
export default Blog;
