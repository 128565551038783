// import "../assets/Styles/WorkExpComp.css";
// import { Button, Divider, MenuItem, Paper, Select } from "@mui/material";
// import React, { useState } from "react";
// import { connect } from "react-redux";
// import BackNextBtnComp from "./BackNextBtnComp";
// import { ADDEXPERIENCE, ADDEXPERIENCEALL } from "../Redux/Actions";
// import { useForm, Controller } from "react-hook-form";
// import InputComponent from "./InputComp";
// import SelectComponent from "./SelectComp";

// const mapStateToProps = (state) => ({
//   experiences: state.workExpReducer.experiences,
// });

// const mapDispatchToProps = (dispatch) => ({
//   setADDEXPERIENCE: (experience) => dispatch(ADDEXPERIENCE(experience)),
//   setADDEXPERIENCEALL: (experiences) => dispatch(ADDEXPERIENCEALL(experiences)),
// });

// const currentYear = new Date().getFullYear();
// const Years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) => 1950 + i);


// // const Years = [
// //   "2025",
// //   "2024",
// //   "2023",
// //   "2022",
// //   "2021",
// //   "2020",
// //   "2019",
// //   "2018",
// //   "2017",
// //   "2016",
// //   "2015",
// //   "2014",
// //   "2013",
// //   "2012",
// //   "2011",
// //   "2010",
// //   "2009",
// //   "2008",
// //   "2007",
// //   "2006",
// //   "2005",
// //   "2004",
// //   "2003",
// //   "2002",
// //   "2001",
// // ];

// const WorkExperienceComponent = (props) => {
//   const {
//     register,
//     handleSubmit,
//     control,
//     formState: { errors },
//   } = useForm();

//   const [loading, setLoading] = useState(false);

//   // Back Click Function
//   const handleBack = () => {
//     props.setTab(props.tab - 1);
//   };

//   // Next Click Function
//   const handleNext = (data) => {
//     setLoading(true);

//     let experienceOne = {};
//     let experienceTwo = {};

//     for (let index in data) {
//       if (index.includes("1")) {
//         experienceOne[index.slice(0, index.length - 1)] = data[index];
//       } else {
//         experienceTwo[index.slice(0, index.length - 1)] = data[index];
//       }
//     }

//     if (Object.keys(experienceTwo).length) {
//       props.setADDEXPERIENCEALL([
//         { ...experienceOne, id: 1 },
//         { ...experienceTwo, id: 2 },
//       ]);
//     } else {
//       props.setADDEXPERIENCEALL([{ ...experienceOne, id: 1 }]);
//     }

//     setTimeout(() => {
//       setLoading(false);
//       props.setTab(props.tab + 1);
//     }, 1000);
//   };

//   //  For adding new Experience
//   const addNewExperience = () => {
//     props.setADDEXPERIENCE({
//       id: props.experiences.length + 1,
//       job_title: "",
//       org_name: "",
//       start_year: "",
//       end_year: "",
//     });
//   };

//   // For adding Job Title
//   const editJobTitleExperience = (value, id) => {
//     const newExperiences = props.experiences.map((experience) => {
//       if (experience.id === id) {
//         return { ...experience, job_title: value };
//       } else return experience;
//     });

//     props.setADDEXPERIENCEALL(newExperiences);
//   };

//   //  For Editing the Organizaton Name
//   const editOrganisationNameExperience = (value, id) => {
//     const newExperiences = props.experiences.map((experience) => {
//       if (experience.id === id) {
//         return { ...experience, org_name: value };
//       } else return experience;
//     });
//     props.setADDEXPERIENCEALL(newExperiences);
//   };

//   return (
//     <Paper className="work-experience-paper col-9" elevation={3}>
//       <h2 className="workExperienceHeading">Work Experience</h2>
//       <form onSubmit={handleSubmit(handleNext)}>
//         {props.experiences.map((experience) => {
//           return (
//             <div key={experience.id} className="experience-cont">
//               <h3 className="experience-heading">Experience {experience.id}</h3>
//               <Divider sx={{ margin: "5px 0px" }} />
//               <div className="experience-form-cont">
//                 <InputComponent
//                   title={"Job Title"}
//                   type={"text"}
//                   asterisk={true}
//                   name={"job_title" + experience.id}
//                   placeholder={"Job Title"}
//                   register={register}
//                   multiline={false}
//                   value={experience.job_title}
//                   setValue={(value) =>
//                     editJobTitleExperience(value, experience.id)
//                   }
//                   error={Boolean(errors[`job_title${experience.id}`])}
//                   errorMessage={
//                     errors[`job_title${experience.id}`]
//                       ? errors[`job_title${experience.id}`].message
//                       : null
//                   }
//                 />
//                 <InputComponent
//                   title={"Org Name"}
//                   type={"text"}
//                   asterisk={true}
//                   name={"org_name" + experience.id}
//                   placeholder={"Org Name"}
//                   register={register}
//                   multiline={false}
//                   value={experience.org_name}
//                   setValue={(value) =>
//                     editOrganisationNameExperience(value, experience.id)
//                   }
//                   error={errors[`org_name${experience.id}`] ? true : false}
//                   errorMessage={
//                     errors[`org_name${experience.id}`]
//                       ? errors[`org_name${experience.id}`].message
//                       : null
//                   }
//                 />
//                 <SelectComponent
//                   title={"Start Year"}
//                   asterisk={true}
//                   errorMessage={
//                     errors[`start_year${experience.id}`]
//                       ? errors[`start_year${experience.id}`].message
//                       : null
//                   }
//                   error={errors[`start_year${experience.id}`] ? true : false}>
//                   <Controller
//                     render={(props) => {
//                       return (
//                         <Select
//                           value={props.field.value}
//                           onChange={props.field.onChange}
//                           error={
//                             errors
//                               ? errors[`start_year${experience.id}`]
//                                 ? true
//                                 : false
//                               : false
//                           }
//                         >
//                           <MenuItem value="" disabled>
//                             Select a start year
//                           </MenuItem>
//                           {Years?.map((year, index) => {
//                             return (
//                               <MenuItem key={index} value={year}>
//                                 {year}
//                               </MenuItem>
//                             );
//                           })}
//                         </Select>
//                       );
//                     }}
//                     name={`start_year${experience.id}`}
//                     control={control}
//                     rules={{ required: "*Please select start year" }}
//                     defaultValue={experience.start_year}
//                   />
//                 </SelectComponent>
//                 <SelectComponent
//                   title={"End Year"}
//                   asterisk={true}
//                   errorMessage={
//                     errors[`end_year${experience.id}`]
//                       ? errors[`end_year${experience.id}`].message
//                       : null
//                   }
//                   error={errors[`end_year${experience.id}`] ? true : false}
//                 >
//                   <Controller
//                     render={(props) => (
//                       <Select
//                         value={props.field.value || 2025}
//                         onChange={props.field.onChange}
//                         error={
//                           errors
//                             ? errors[`end_year${experience.id}`]
//                               ? true
//                               : false
//                             : false
//                         }>
//                         <MenuItem value="" disabled>
//                           Select a end year
//                         </MenuItem>
//                         {Years?.map((year, index) => {
//                           return (
//                             <MenuItem key={index} value={year}>
//                               {year}
//                             </MenuItem>
//                           );
//                         })}
//                       </Select>
//                     )}
//                     name={"end_year" + experience.id}
//                     control={control}
//                     rules={{ required: "*Please select end year" }}
//                     defaultValue={experience.end_year || 2025}
//                   />
//                 </SelectComponent>
//               </div>
//             </div>
//           );
//         })}
//         {props.experiences.length === 3 ? null : (
//           <div className="add-new-btn-cont">
//             <Button onClick={addNewExperience} variant="text">
//               Add New
//             </Button>
//           </div>
//         )}
//         <Divider sx={{ margin: "10px 0px" }} />
//         <BackNextBtnComp
//           onNext={handleNext}
//           onBack={handleBack}
//           loading={loading}
//           tab={props.tab}
//           nextTitle={"Next"}
//           backTitle={"Back"}
//         />
//       </form>
//     </Paper>
//   );
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(WorkExperienceComponent);


import "../assets/Styles/WorkExpComp.css";
import { Button, Divider, MenuItem, Paper, Select } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React, { useState } from "react";
import { connect } from "react-redux";
import BackNextBtnComp from "./BackNextBtnComp";
import { ADDEXPERIENCE, ADDEXPERIENCEALL } from "../Redux/Actions";
import { useForm, Controller } from "react-hook-form";
import InputComponent from "./InputComp";
import SelectComponent from "./SelectComp";

const mapStateToProps = (state) => ({
  experiences: state.workExpReducer.experiences,
});

const mapDispatchToProps = (dispatch) => ({
  setADDEXPERIENCE: (experience) => dispatch(ADDEXPERIENCE(experience)),
  setADDEXPERIENCEALL: (experiences) => dispatch(ADDEXPERIENCEALL(experiences)),
});

const currentYear = new Date().getFullYear();
const Years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) => 1950 + i);


const WorkExperienceComponent = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  // Back Click Function
  const handleBack = () => {
    props.setTab(props.tab - 1);
  };


  // const handleNext = (data) => {
  //   setLoading(true);

  //   let experiencesMap = {};

  //   Object.keys(data).forEach((key) => {
  //     const match = key.match(/(\D+)(\d+)$/); // Matches "job_title16" → ["job_title16", "job_title", "16"]

  //     if (match) {
  //       const fieldName = match[1]; 
  //       const experienceId = parseInt(match[2], 10);

  //       if (!experiencesMap[experienceId]) {
  //         experiencesMap[experienceId] = { id: experienceId };
  //       }

  //       experiencesMap[experienceId][fieldName] = data[key];
  //     }
  //   });
  //   const updatedExperiences = Object.values(experiencesMap);

  //   props.setADDEXPERIENCEALL(updatedExperiences);

  //   setTimeout(() => {
  //     setLoading(false);
  //     props.setTab(props.tab + 1);
  //   }, 1000);
  // };

  const handleNext = (data) => {
    setLoading(true);
    let experiencesMap = {};

    Object.keys(data).forEach((key) => {
      const match = key.match(/(\D+)(\d+)$/);
      if (match) {
        const fieldName = match[1];
        const experienceId = parseInt(match[2], 10);

        if (!experiencesMap[experienceId]) {
          experiencesMap[experienceId] = { id: experienceId, deleted: false };
        }

        experiencesMap[experienceId][fieldName] = data[key];
      }
    });

    const updatedExperiences = Object.values(experiencesMap);

    const finalExperiences = updatedExperiences.map((exp) => {
      const existingExp = props.experiences.find((e) => e.id === exp.id);
      return existingExp ? { ...exp, deleted: existingExp.deleted } : exp;
    });

    props.setADDEXPERIENCEALL(finalExperiences);

    setTimeout(() => {
      setLoading(false);
      props.setTab(props.tab + 1);
    }, 1000);
  };


  const addNewExperience = () => {
    props.setADDEXPERIENCE({
      id: props.experiences.length + 1,
      job_title: "",
      org_name: "",
      start_year: "",
      end_year: "",
      description: "",  // Add description here
    });
  };

  // const deleteExperience = (id) => {
  //   const updatedExperiences = props.experiences.filter(
  //     (experience) => experience.id !== id
  //   );
  //   props.setADDEXPERIENCEALL(updatedExperiences);
  // };

  const deleteExperience = (id) => {
    const updatedExperiences = props.experiences.map((experience) =>
      experience.id === id ? { ...experience, deleted: true } : experience
    );
    props.setADDEXPERIENCEALL(updatedExperiences);
  };


  // For adding Job Title
  const editJobTitleExperience = (value, id) => {
    const newExperiences = props.experiences.map((experience) => {
      if (experience.id === id) {
        return { ...experience, job_title: value };
      } else return experience;
    });

    props.setADDEXPERIENCEALL(newExperiences);
  };

  const editOrganisationNameExperience = (value, id) => {
    const newExperiences = props.experiences.map((experience) => {
      if (experience.id === id) {
        // Update organization name for the specific experience id
        return { ...experience, org_name: value };
      }
      return experience; // keep other experiences unchanged
    });
    props.setADDEXPERIENCEALL(newExperiences);
  }


  const editDescriptionExperience = (value, id) => {
    if (value.length > 200) return; // Prevent input exceeding 250 characters

    const newExperiences = props.experiences.map((experience) => {
      if (experience.id === id) {
        return { ...experience, description: value };
      }
      return experience;
    });
    props.setADDEXPERIENCEALL(newExperiences);
  };

  return (
    <Paper className="work-experience-paper col-9" elevation={3}>
      <h2 className="workExperienceHeading">Work Experience</h2>
      <form onSubmit={handleSubmit(handleNext)}>
        {/* {props?.experiences.map((experience, index) => { */}
        {props.experiences
          .filter((experience) => !experience.deleted) // Hide deleted experiences
          .map((experience, index) => {
            return (
              <>
                <div key={experience.id} className="experience-cont py-3 px-2 mt-4">
                  <h3 className="experience-heading mt-3">Experience {index + 1}</h3>

                  {/* <Divider sx={{ margin: "5px 0px" }} /> */}

                  {index !== 0 && (
                    <div className="d-flex justify-content-end mt-0">
                      <DeleteOutlineOutlinedIcon
                        sx={{ marginLeft: "10px", marginTop: "0px", color: "#f14747", cursor: 'pointer' }}
                        onClick={() => deleteExperience(experience.id)}
                      />
                    </div>
                  )}

                  <div className="experience-form-cont">
                    <InputComponent
                      title={"Job Title"}
                      type={"text"}
                      asterisk={true}
                      name={"job_title" + experience.id}
                      placeholder={"Job Title"}
                      maxLength={"35"}
                      register={register}
                      multiline={false}
                      value={experience.job_title}
                      setValue={(value) =>
                        editJobTitleExperience(value, experience.id)
                      }
                      error={Boolean(errors[`job_title${experience.id}`])}
                      errorMessage={
                        errors[`job_title${experience.id}`]
                          ? errors[`job_title${experience.id}`].message
                          : null
                      }
                    />
                    <InputComponent
                      title={"Org Name"}
                      type={"text"}
                      asterisk={true}
                      name={"org_name" + experience.id}
                      placeholder={"Org Name"}
                      maxLength={"35"}
                      register={register}
                      multiline={false}
                      value={experience.org_name}
                      setValue={(value) =>
                        editOrganisationNameExperience(value, experience.id)
                      }
                      error={errors[`org_name${experience.id}`] ? true : false}
                      errorMessage={
                        errors[`org_name${experience.id}`]
                          ? errors[`org_name${experience.id}`].message
                          : null
                      }
                    />
                    <SelectComponent
                      title={"Start Year"}
                      asterisk={true}
                      errorMessage={
                        errors[`start_year${experience.id}`]
                          ? errors[`start_year${experience.id}`].message
                          : null
                      }
                      error={errors[`start_year${experience.id}`] ? true : false}>
                      <Controller
                        render={(props) => {
                          return (
                            <Select
                              value={props.field.value}
                              onChange={props.field.onChange}
                              error={
                                errors
                                  ? errors[`start_year${experience.id}`]
                                    ? true
                                    : false
                                  : false
                              }
                            >
                              <MenuItem value="" disabled>
                                Select a start year
                              </MenuItem>
                              {Years?.map((year, index) => {
                                return (
                                  <MenuItem key={index} value={year}>
                                    {year}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          );
                        }}
                        name={`start_year${experience.id}`}
                        control={control}
                        rules={{ required: "*Please select start year" }}
                        defaultValue={experience.start_year}
                      />
                    </SelectComponent>
                    <SelectComponent
                      title={"End Year"}
                      asterisk={true}
                      errorMessage={
                        errors[`end_year${experience.id}`]
                          ? errors[`end_year${experience.id}`].message
                          : null
                      }
                      error={errors[`end_year${experience.id}`] ? true : false}
                    >
                      <Controller
                        render={(props) => (
                          <Select
                            value={props.field.value || 2025} // Provide default value if undefined
                            onChange={props.field.onChange}
                            error={errors[`end_year${experience.id}`] ? true : false}
                          >
                            <MenuItem value="" disabled>
                              Select an end year
                            </MenuItem>
                            {Years?.map((year, index) => {
                              return (
                                <MenuItem key={index} value={year}>
                                  {year}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                        name={"end_year" + experience.id}
                        control={control}
                        rules={{ required: "*Please select end year" }}
                        defaultValue={experience.end_year || 2025}
                      />
                    </SelectComponent>
                  </div>

                  {/* New Input for Description */}
                  <InputComponent
                    title={"Description"}
                    type={"text"}
                    asterisk={true}
                    name={"description" + experience.id}
                    placeholder={"Description"}
                    register={register}
                    multiline={true}
                    value={experience.description}
                    setValue={(value) => editDescriptionExperience(value, experience.id)}
                    error={Boolean(errors[`description${experience.id}`])}
                    errorMessage={
                      errors[`description${experience.id}`]
                        ? errors[`description${experience.id}`].message
                        : null
                    }
                  />
                </div>

              </>
            );
          })}
        <Divider sx={{ margin: "10px 0px" }} />

        {props.experiences.length === 10 ? null : (
          <div className="add-new-btn-cont">
            <Button onClick={addNewExperience} type="button" variant="text">
              Add New
            </Button>
          </div>
        )}
        <BackNextBtnComp
          onNext={handleNext}
          onBack={handleBack}
          loading={loading}
          tab={props.tab}
          nextTitle={"Next"}
          backTitle={"Back"}
        />
      </form >
    </Paper >
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkExperienceComponent);
