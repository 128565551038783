import React from "react";
import "../assets/Styles/TemplateHeader.css";
import profileImage from "../assets/Images/noProfile.jpg";

const TemplateHeader = (props) => {
  // const NoPic =
  //   props?.personalInfo?.firstName[0] +
  //   (props?.personalInfo?.lastName?.length > 0
  //     ? props?.personalInfo?.lastName[0]
  //     : " ");

  const NoPic =
    (props?.personalInfo?.firstName?.[0] ?? "") +
    (props?.personalInfo?.lastName?.[0] ?? "");

  const displayNoPic = NoPic.trim() || "?";
  return (
    <>
      <div style={{ backgroundColor: props?.bgColor }}>
        <div className="template-header">
          <div className="template-header-first">
            {props?.personalInfo?.profile?.length > 0 ? (
              <div className="template-image-comp">
                <img
                  className="template-profile-img"
                  src={`https://resumecv.idea2reality.tech/storage/Profile/${props?.personalInfo?.profile}`}
                  alt="Profile"
                />
              </div>
            ) : props?.personalInfo?.profileImg?.length > 0 ? (
              <div className="template-image-comp">
                <img
                  className="template-profile-img"
                  src={props?.personalInfo?.profileImg}
                  alt="Profile"
                />
              </div>
            ) : !displayNoPic ? (
              <div className="template-image-comp">
                <img
                  className="template-profile-img"
                  src={profileImage}
                  alt="Profile"
                />
              </div>
            ) : (
              <div
                className="template-image-comp"
                style={{ backgroundColor: props?.primaryColor }}>
                <p className="template-img-text"
                  style={{ color: props?.bgColor }}>
                  {displayNoPic}
                </p>
              </div>
            )}

            <div className="template-user-details-comp">
              <h2
                className="template-user-name"
                style={{ color: props?.primaryColor }}>
                {props?.personalInfo.firstName +
                  " " +
                  props?.personalInfo.lastName}
              </h2>
              <p
                className="template-user-designation"
                style={{ color: props?.secondaryColor }}>
                {props?.workExperience[0].job_title}
              </p>
            </div>
          </div>

          <p style={{ color: props?.primaryColor }}
            className="template-header-address">
            {props?.personalInfo.address +
              " " +
              props?.personalInfo.city +
              " " +
              props?.personalInfo.state +
              " " +
              props?.personalInfo.country +
              " " +
              props?.personalInfo.postalCode}
          </p>

          <div style={{ paddingRight: "10px" }}>
            <p
              style={{ color: props?.primaryColor }}
              className="template-header-second mt-0">
              {props?.personalInfo.email}
            </p>
            <p
              style={{ color: props?.primaryColor }}
              className="template-header-second mt-0">

              {props?.personalInfo?.country_code && props?.personalInfo?.phone
                ? `${props.personalInfo.country_code}${props.personalInfo.phone}`
                : ""}

            </p>
          </div>
        </div>
        <p
          className="template-user-objective"
          style={{ color: props?.secondaryColor, paddingBottom: "5px" }}>
          {props?.personalInfo.objective}
        </p>
      </div>
    </>
  );
};

export default TemplateHeader;
