import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


import { Navigation, Pagination } from 'swiper/modules';
import "../../assets/Styles/Section2.css";

const Section2 = () => {
    const swiperRef = useRef(null);
    const reviews = [
        {
            rating: 1,
            text: 'Great website, a bit pricy but interface and options are very rich!',
            name: 'Paul',
            time: 'about 5 hours ago',
            subheading: "Works well and easy to navigate resume…"
        },
        {
            rating: 5,
            text: 'It is amazing, detail creating CV and amazing sales catching cover letter.',
            name: 'Farhad Keihan',
            time: 'about 16 hours ago',
            subheading: "Very helpful support staff"
        },
        {
            rating: 3,
            text: 'Always helpful when I need to update my resume for career opportunities.',
            name: 'Chynii Dreamer',
            time: '1 day ago',
            subheading: "Works well and easy to navigate resume…"
        },
        {
            rating: 2,
            text: 'Great website, a bit pricy but interface and options are very rich!',
            name: 'Paul',
            time: 'about 5 hours ago',
            subheading: "Very helpful support staff"
        },
        {
            rating: 1,
            text: 'It is amazing, detail creating CV and amazing sales catching cover letter.',
            name: 'Jack',
            time: 'about 1 year ago',
            subheading: "Very helpful support staff"
        },
    ];

    return (
        <>
            {/* <div className="reviews-section">
                <h2>Reviewed by the community. Trusted <br />
                    by professionals</h2>
                <div className="review-plus-slider">
                    <div className="rating-first-div">
                        <div className=""><h5 className="m-0">4.5 out of 5</h5></div>
                        <div className="stars-rating-outoff">
                            {'★'.repeat(4)}{'☆'}
                        </div>

                        <div>
                            <span className="stars">★</span><b className='font-b-sec-2'>Trustpilot</b>
                            <p className="dot-class-resume-created my-0">based on 52,237 reviews</p>
                        </div>
                    </div>

                    <Swiper
                        modules={[Navigation, Pagination]}
                        spaceBetween={50}
                        slidesPerView={4}
                        navigation
                        pagination={{ clickable: true }}
                        breakpoints={{

                            350: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1280: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        className="mySwiper mt-4"
                    >
                        {reviews?.map((review, index) => (
                            <SwiperSlide key={index} className="review-slide">
                                <div className="review-card">
                                    <div className="stars">{'★'.repeat(review.rating)}</div>
                                    <h5>{review.subheading}</h5>
                                    <p>{review.text}</p>
                                    <div className="reviewer-details">
                                        <span className="reviewer-name">{review.name}</span>
                                        <span className="review-time">{review.time}</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div> */}

            <div className="reviews-section">
                <h2>Reviewed by the community. Trusted <br /> by professionals</h2>

                {/* Custom Navigation Buttons */}
                <div className="custom-navigation">
                    <button onClick={() => swiperRef.current.swiper.slidePrev()} className="custom-prev">
                    </button>
                    <button onClick={() => swiperRef.current.swiper.slideNext()} className="custom-next">
                    </button>
                </div>



                <div className="review-plus-slider">
                    <div className="rating-first-div">
                        <div className=""><h5 className="m-0">4.5 out of 5</h5></div>
                        <div className="stars-rating-outoff">
                            {'★'.repeat(4)}{'☆'}
                        </div>

                        <div>
                            <span className="stars">★</span><b className='font-b-sec-2'>Trustpilot</b>
                            <p className="dot-class-resume-created my-0">based on 52,237 reviews</p>
                        </div>
                    </div>

                    <Swiper
                        ref={swiperRef}
                        modules={[Pagination]}
                        spaceBetween={50}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            350: { slidesPerView: 1, spaceBetween: 10 },
                            640: { slidesPerView: 1, spaceBetween: 10 },
                            768: { slidesPerView: 2, spaceBetween: 20 },
                            1024: { slidesPerView: 3, spaceBetween: 30 },
                            1280: { slidesPerView: 3, spaceBetween: 50 },
                        }}
                        className="mySwiper mt-4"
                    >
                        {reviews?.map((review, index) => (
                            <SwiperSlide key={index} className="review-slide">
                                <div className="review-card">
                                    <div className="stars">{'★'.repeat(review.rating)}</div>
                                    <h5>{review.subheading}</h5>
                                    <p>{review.text}</p>
                                    <div className="reviewer-details">
                                        <span className="reviewer-name">{review.name}</span>
                                        <span className="review-time">{review.time}</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <hr />
        </>
    );
};

export default Section2;
