import axios from "axios";
import API_CONFIG from "../apiConfig";
import axiosInstance from "../axiosInstance";

const create_Resume = (fData) => {
  return axiosInstance.post(`${API_CONFIG.baseURL}resume/create`, fData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const get_resume = () => {
  return axiosInstance.get(`${API_CONFIG.baseURL}resume/get_resume`);
};

const get_Resume_EditData = (id) => {
  return axiosInstance.get(`${API_CONFIG.baseURL}resume/edit/${id}`);
};

const edit_Resume = (id, fData) => {
  return axiosInstance.post(`${API_CONFIG.baseURL}resume/update/${id}`, fData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const get_resume_with_uuid = (uuid) => {
  // return axiosInstance.get(`${API_CONFIG.baseURL}dashboard/${uuid}`);
  return axiosInstance.get(`${API_CONFIG.baseURL}get_dashbord/${uuid}`);
};

const update_resume = (id, data) => {
  return axiosInstance.post(
    `${API_CONFIG.baseURL}resume/change_name/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export {
  create_Resume,
  get_resume,
  get_Resume_EditData,
  edit_Resume,
  get_resume_with_uuid,
  update_resume,
};
